import React from 'react';
import DiscussProject from 'components/parts/discuss-project';
import FeaturedWork from 'components/parts/featured-work';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import 'components/style.scss';

const LayoutSantosTour = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      screenshot1: file(relativePath: { eq: "santostour/screenshot1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot2: file(relativePath: { eq: "santostour/screenshot2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot3: file(relativePath: { eq: "santostour/screenshot3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="big62-section">
      <div className="big62-container">
        <h2 className="title is-7 is-family-secondary">FEATURED WORK</h2>
        <div className="margin-top-3 margin-bottom-5">
          <FeaturedWork />
        </div>
        <div className="margin-top-3 margin-bottom-3">
          <h1 className="title is-main-title is-family-secondary">
            <span className="has-text-big62pink">Mapping</span> the southern hemisphere's{' '}
            <span className="has-text-big62pink">biggest</span> cycling race.
          </h1>
        </div>
        <div className="box embed-container margin-bottom-3">
          <iframe src="https://youtube.com/embed/Zlhev8ZGNO4" frameborder="0" allowfullscreen></iframe>
        </div>
        <div className="content margin-top-1 margin-bottom-3">
          <h2 className="title is-7 is-family-secondary">OVERVIEW</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            The Tour Down Under is a cycling race in and around Adelaide, and is the opening event of the UCI World Tour
            which includes the Tour De France, and features all 19 UCI WorldTeams.
          </p>
          <br />
          <p className="is-body-small-text fix-spacing">
            We were tasked with creating an animated race course map for live{' '}
            <span className="has-text-big62pink">TV broadcast</span> using accurate{' '}
            <span className="has-text-big62pink">3d terrain</span> and{' '}
            <span className="has-text-big62pink">photorealistic mapping</span>, across 857KM of road bicycle racing.
          </p>
        </div>
        <Img className="bordered-image" fluid={data.screenshot1.childImageSharp.fluid} alt="Screenshot 1" />
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot2.childImageSharp.fluid} alt="Screenshot 2" />
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot3.childImageSharp.fluid} alt="Screenshot 3" />
        <br />
        <br />
        <div className="content margin-top-1">
          <h2 className="title is-7 is-family-secondary">WHAT WE DID</h2>
          <br />
          <p className="is-body-small-text fix-spacing">
            Our main challenge was ensuring accurate terrain replication of the circuit the cyclists would take on race
            day, which could and did change due to environmental factors, and doing so at a high enough resolution for
            wide and close shots.
          </p>
          <br />
          <p className="is-body-small-text fix-spacing">
            Through extensive use of <span className="has-text-big62pink">satellite mapping</span>,{' '}
            <span className="has-text-big62pink">3d mapping</span>,{' '}
            <span className="has-text-big62pink">animation</span> and{' '}
            <span className="has-text-big62pink">software solutions </span> we were able to deliver highly effective
            race broadcast quality animations for all seven circuits.
          </p>
          <br />
          <p className="is-body-small-text fix-spacing">
            The animations were shown on television worldwide during the live race broadcast, and in social media.
          </p>
        </div>
        <br />
        <div className="big62-section" className="margin-top-4">
          <DiscussProject nextText="BACK HOME >" nextUrl="/" />
        </div>
      </div>
    </div>
  );
};

export default LayoutSantosTour;
